.container {
	@include center(1176px, 15px);

	@include breakpoint($desktop-hd) {
		margin: 0 auto;
	}
}

.row {
	@include cf();
	clear: both;
}

.full {
	@include col(1);
}

.blog {
	@include breakpoint($tablet) {
		.main-content {
			@include col(2/3);
		}

		.sidebar {
			@include col(1/3);
		}
	}
}

@include breakpoint($tablet) {
	.col1-2{
		@include col(1/2);
	}

	.col1-3 {
		@include col(1/3, $cycle: 3);
	}

	.col2-3 {
		@include col(2/3);
	}

	.col1-4 {
		@include col(1/4);
	}

	.span1-4 {
		@include span(1/4);
	}

	.col5-12 {
		@include col(5/12);
	}

	.col7-12 {
		@include col(7/12);
	}
}

section {
	@include cf();
}
