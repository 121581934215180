.btn {
	padding: 15px 30px;
	font-size: 1.2em;
	font-weight: 500;
	color: #FFF;
	background: #289743;
	text-align: center;
	min-width: 215px;
	transition: background .3s, color .3s;
	border: none;
	
	display: block;
	width: 100%;

	&:hover {
		// background: darken($main-color, 10%);
		text-decoration: none;
		background: darken(#289743, 10%);
		color: #FFF;
	}
}
