#google-maps {
    position: relative;
    .info * {
        color: #000
    }
    .address {
        position: absolute;
        top: 10px;
        left: 10px;
        background: #fff;
        z-index: 40;
        width: 50%;
        padding: 5px;
        @include breakpoint($desktop-hd) {
            left: calc((100% - 1165px) / 2);
            width: 580px;
        }
    }
    #directions {
        overflow: auto;
        max-height: 400px;
        height: 0;
        transition: height .3s;
        &.opened {
            height: 80%;
        }
    }
    input {
        width: 90%;
        margin-bottom: 0;
        border: 0;
        padding: 5px;
    }
    img {
        //max-width: 10000% !important;
    }
}

#getDirections {
    float: right;
    margin-top: 9px;
    margin-right: 12px;
    display: none;
    transition: opacity .3s;
    &.opened {
        opacity: .2;
    }
}