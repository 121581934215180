.page-footer {
	@include cf();
	clear: both;

	font-size: .7em; 
	text-align: center;
	text-transform: uppercase;
	padding: 7px 0;
	background: #384444;
	color: #FFF;

	@include breakpoint($phablet) {
		text-align: left;

		.copy {
			float: left;
			display: block;
		}

		.credits {
			float: right;
		}

		.copy {
			display: inline;
		}
    }
    
    p {
        margin: 5px 0;
    }

	a {
		color: #FFF;
		text-decoration: none;
	}
}