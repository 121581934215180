#contato {
    background: #e6e7e8;
    padding: 70px 0 0;

    p {
        line-height: 1.35em;
        margin: 0.5em 0 0.8em;
    }

    address p:first-child {
        color: #289743;

        strong {
            color: #000;
        }
    }

    a {
        &:hover {
            text-decoration: none;
            color: $main-color;
        }
    }
 
    p:first-child { margin-top: 0; }

    .map-container {
        position: relative;
    }

    address {
        font-style: normal;

        strong {
            font-weight: 700
        }
    }

    iframe {
        min-height: 400px;
    }

    strong {
        font-weight: 500;
    }

    [type=submit] {
        margin-bottom: 90px;
        float: right;
    }

    .map {
        width: 100%;
    }

    .svg-share {
        width: 32px;
        height: 32px;
        vertical-align: middle;
        margin-left: 5px;
    }

    @include breakpoint($desktop-hd) {
        iframe {
            min-height: 555px;
        }
    }

    small {
        line-height: 16px;
    }
}