.site-section {
    padding: 90px 0 80px;
}

.section-title {
    text-align: center;
    font-weight: 500;
    margin-bottom: 50px;
    line-height: 1.4;
    font-size: 2.2em;

    span {
        color: $main-color;
    }
}