// Bootstrap
@import 'breakpoint-sass/stylesheets/breakpoint';
@import 'jeet/scss/jeet/index.scss';

// Utils
@import 'normalize-scss/_normalize.scss';
//@import 'partials/animate';

// Configurations@import 'core/__variables.scss';
@import 'core/_grid.scss';
@import 'atoms/_base.scss';
@import 'atoms/_buttons.scss';
@import 'atoms/_forms.scss';
@import 'molecules/_fullbanner.scss';
@import 'molecules/_google-maps.scss';
@import 'molecules/_navigation.scss';
@import 'organisms/_footer.scss';
@import 'organisms/_header.scss';
@import 'organisms/_sections.scss';
@import 'organisms/_swiper.scss';
/* No files to import found in templates//* */@import 'pages/_contato.scss';
@import 'pages/_home.scss';
@import 'pages/_quem-somos.scss';

//@import 'slick-carousel/slick/slick.scss';@import 'vendors/_swiper.scss';
