*,
*::after,
*::before {
	box-sizing: border-box;
}

body {
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	line-height: 1.4em;
	color: #000;
}

h1 {
	font-size: 1.8em;
	line-height: 1em;
	margin-top: 0;
	margin-bottom: 60px;
}

em {
	font-style: normal;
	color: $main-color;
}

@include breakpoint($tablet) {
	h1 { font-size: 1.9em }
}

h1, h2 {
	color: #000;
	font-weight: 400;
}

h2 {
	margin-top: 0;
	font-size: 1.8em;
}

img {
	max-width: 100%;
	height: auto;
}

figure {
	margin: 0;
	margin-bottom: 15px;
	text-align: center;
}

p, li {
	font-size: 1.2em;
	line-height: 1.4em;
	font-weight: 300;
	margin: .7em 0 1.5em;
}

strong {
	font-weight: 700;
}

hr {
	background: #736960;
	border: none;
	height: 2px;
	margin: 40px 0 30px;
}

a {
	color: #000;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}
