.page-header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
    z-index: 1500;
    background: $main-color;

	> .container {
		position: relative;
    }
}

.brand {
    padding: 18px 0 13px;

    h1 {
        margin: 0;
        padding: 0;
    }

	img {
		width: 100%;
        max-width: 170px;
        margin-top: 7px;
        transition: max-width .3s;

        @include breakpoint($phablet) {
            max-width: 312px;
            margin-top: 0;

            .stick & {
                max-width: 200px;
            }
        }
	}
}