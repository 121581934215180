#quem-somos {
    background: #404f4d;
    color: #FFF;
    text-align: center;

    h1 {
        color: #FFF;
    }

    p {
        margin-left: auto;
        margin-right: auto;
        max-width: 540px;
    }

    .pack {
        margin-bottom: 70px;
    }
}

#como-funciona {
    background: #e6e7e8;

    ul {
        list-style: none;
        padding-left: 0;
        text-align: center;
    }

    .icon {
        width: 120px;
        height: 120px;
        display: block;
        margin: 0 auto;
    }

    li {
        font-weight: 700;
        margin-bottom: 50px;
        
        @include breakpoint($tablet) {
            @include col(1/2, $cycle: 2);
        }

        @include breakpoint($desktop) {
            @include col(1/4, $uncycle: 2);
        }
    }
}

#tela-de-orientacao {
    background: #000;
    
    h2 {
        color: #FFF;
        margin-bottom: 10px;
    }
}

#frase1 {
    background: url('../img/frase1-bg.jpg') no-repeat center center;
    background-size: cover;
    padding: 100px 0;

    .container {
        padding: 0;
    }

    p {
        margin: 0 10%;
        padding: 30px;
        background: $main-color;
        color: #FFF;
        font-size: 1.4em;
        text-align: center;
    }

    @include breakpoint($tablet) {
        padding: 220px;
        
        p {
            padding: 30px 100px;
        }
    }
}

#beneficios, #diferenciais {
    background: #2f3b3a;
    text-align: center;
    padding-bottom: 0;
    padding-top: 40px;
    
    @include breakpoint($tablet) {
        .section-bg {
            background: linear-gradient(to right, #384444 0%, #384444 50%, #516360 51%, #516360 100%);
        }
    }

    h2 {
        color: $main-color;
        margin-bottom: 35px;
    }

    ul {
        list-style: none;
        padding-left: 0;
        margin: 0;
        
        @include breakpoint($tablet) {
            display: flex;
            align-items: stretch;
        }
    }

    li {
        color: #FFF;
        margin-bottom: 60px;
        background: #384444;
        padding: 50px 15px 70px;

        &:nth-child(even) {
            background: #516360;
        }

        strong {
            display: block;
            color: $main-color;
        }

        @include breakpoint($tablet) {
            @include span(1/4);
            margin: 0;
        }
    }

    .icon {
        fill: $main-color;
        width: 85px;
        height: 85px;
        display: block;
        margin: 0 auto;
        margin-bottom: 25px;
    }
}

#frase2 {
    background: url('../img/frase2-bg.jpg') no-repeat center center;
    background-size: cover;

    h1 {
        font-weight: 700;
        text-align: center;
        color: #FFF;
        margin-bottom: 0;
    }

    p {
        margin: 0 10%;
        padding: 30px 15px;
        color: #FFF;
        font-size: 1.4em;
        text-align: center;
        font-weight: 400;

        a {
            color: $main-color;
        }
    }

    @include breakpoint($tablet) {
        p {
            padding: 30px 100px;
        }
    }
}

#produtos, #depoimentos {
    ul {
        list-style: none;
        padding-left: 0;
        text-align: center;
    }

    li {
        strong, img {
            display: block;
        }

        strong {
            margin-top: 28px;
            text-transform: uppercase;
        }

        @include breakpoint($tablet) {
            @include col(1/3);
        }
    }
}

#produtos {
    li img {
        margin: 0 auto;
    }
}

#depoimentos {
    ul {
        text-align: left;
    }

    p {
        font-size: 1em;
        font-weight: 400;
    }

    blockquote {
        margin-left: 0;
        margin-right: 0;
    }
    
	@include breakpoint(max-width $tablet) {
		li {
            margin-bottom: 60px;

            &:last-child {
                margin-bottom: 0
            }
        }
	}
}

.photo-gallery, .video-gallery {
    background: #000;

    h2 {
        color: #FFF;
    }
}

.video-gallery {
    background: url('../img/videos-bg.jpg') no-repeat center center;
    background-size: cover;
    padding-bottom: 80px;

    iframe {
        display: block;
    }

    .swiper-button-prev,
	.swiper-button-next {
		background-size: 25px;
		opacity: 1;
		padding: 60px 25px;
		bottom: 50%;
		margin-bottom: -60px;
		top: auto;
		opacity: 1 !important;
	}

	
	@include breakpoint($desktop-hd) {
		.swiper-button-prev {
			left: calc((100% - 1175px) / 2);
		}
		
		.swiper-button-next {
			right: calc((100% - 1175px) / 2);
		}
	}

    .swiper-slide {
        max-width: 300px;
        position: relative;

        iframe {
            width: 300px;
            height: 175px;
            margin: 0 auto;
            display: block;
        }

        @include breakpoint($tablet) {
            max-width: 760px;

            iframe {
                width: 760px;
                height: 443px;
            }
        }

        @include breakpoint($desktop) {
            max-width: 950px;

            iframe {
                width: 950px;
                height: 533px;
            }
        }

        @supports(mix-blend-mode:color) {
            &:not(.swiper-slide-active) {
                &:after {
                    content: " ";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    // left: 50%;
                    background: rgba(0, 0, 0, 0.8);
                    mix-blend-mode: multiply;
                    z-index: 2;
                    // width: 300px;
                    // height: 175px;
                    // margin-left: -150px;

                    // @include breakpoint($tablet) {
                    //     width: 760px;
                    //     height: 443px;
                    //     margin-left: -380px;
                    // }

                    // @include breakpoint($desktop) {
                    //     width: 950px;
                    //     height: 533px;
                    //     margin-left: -475px;
                    // }
                }
            }
        }
    }
}

.youtube-video {
    width: 300px;
    height: 175px;
    margin: 0 auto;
    display: block;
    
    @include breakpoint($tablet) {
        width: 760px;
        height: 443px;
    }

    @include breakpoint($desktop) {
        width: 950px;
        height: 533px;
    }
}