#home {
    background: no-repeat center center;
    background-size: cover;
    //height: 820px;
    position: relative;
    text-align: center;
    padding: 0;

    .arrow {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAARCAMAAAFlfbftAAAAilBMVEX////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////6DghSAAAALXRSTlMAFBgbHSEkJikrLi8xNTY5O0BHSFeksbK6u8DBx8jJys/S1djZ3N3g4uPm6O1eBMO4AAAA0klEQVQoz3WP2WKCMBREj4gQY1XQum8RcUPn/3+vDwSEls7TTM7k3oQpAMIC3wAkQIiXAEwMoF7qKTAlNhW3oe+R9uTvlaOMLLWsDDBUVMZIQw8mCiDQ5FNlJs28XdLUkmPWiNkRnKtjabOTjydfve0B2N+q3n0H7O6fOc8162dzz6t4tfaSV2ah3NItm2sBkVMWd9A4kyv/bs5yg1904HQ2dRpddAgbNDzoMmr1x1dt+973t7qO/+xLHtoEQLDRI+l8b1po9bVSkfKf5m+9562TH6vKDxCz+hklAAAAAElFTkSuQmCC) no-repeat center center;
        display: block;
        width: 40px;
        height: 40px;
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -20px;
        z-index: 2;
        animation: bounce 2s infinite;
    }

    .text-box {
        max-width: 620px;
        width: 80%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 20px;
        background: rgba(0,0,0,0.85);

        @include breakpoint($tablet) {
            padding: 40px 80px 30px;
        }
    }

    h1 {
        color: #FFF;
        font-weight: 700;
        font-size: 2em;
        line-height: 1.2em;
        margin-bottom: 0px;
    }

    p {
        position: static;
        text-transform: none;
        font-weight: 500;
        margin-bottom: 0;
    }

    @include breakpoint($tablet) {
        h1 {
            font-size: 3em;
        }
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
    40% {transform: translateY(-15px);}
    60% {transform: translateY(-10px);}
}