label {
	display: block;
	padding: 5px 0;
	display: none;
	text-align: left;
	text-transform: uppercase;
}

fieldset {
	border: none;
	padding: 0;
	margin: 20px 0;
}

legend {
	font-weight: bold;
	text-transform: uppercase;
	margin-bottom: 5px;
	padding-left: 1.5%;
	display: block;
	width: 90%;
}

input, textarea, select, option {
	width: 100%;
	display: block;
	margin-bottom: 10px;
	border: none;
	padding: 21px;
	color: #777;
	border: 1px solid #000;
	font-size: 1.1em;
	background: transparent
}

textarea {
	height: 153px !important;
}

[type=submit] {
	// display: block;
	// padding: 17px;
	// text-align: center;
	// font-size: 1em;
	// width: 100%;
	// margin: 0 auto;
	// display: block;
	// border: none;
	// color: #FFF;
	// text-transform: uppercase;
	// transition: .3s all;
	// font-weight: 300;

	// &:hover{
	// 	background: darken($main-color, 10%);
	// }
}

.sr-only {
	display: none;
}

label.error {
	background: red;
	color: #FFF;
	//position: relative;
	display: block;
	font-size: .9em;
	width: 100%;
	text-align: left;
	text-transform: none;
	margin: -20px 0 10px;
	padding-left: 10px;
}

input.error, textarea.error {
	color: red;
	background: #ffd4fc;
	border: 2px solid red;
}


::-webkit-input-placeholder {
	color: #a7a7a8;
}

:-moz-placeholder {
	color: #a7a7a8;
}

::-moz-placeholder {
	color: #a7a7a8;
}

:-ms-input-placeholder {
	color: #a7a7a8;
}
