nav {
	position: absolute;
	top: 0;
	right: 10px;
}

.menu-text {
	display: none;
}

.menu-trigger {
    position: absolute;
    top: 0;
    right: 0;
    height: 79px;
    width: 79px;
    margin-top: 0;
    margin-right: 0;
    z-index: 1700;
    /* image replacement */
    //overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    cursor: pointer;
    float: left;
    transition: background .3s;

    @include breakpoint($tablet) {
        top: 10px;
        right: 15px;

        .stick & {
            top: -2px
        }

        &::before {
            content: "MENU";
            display: block;
            color: #FFF;
            font-weight: 400;
            margin: 29px 0 0 -120px;
            
            .opened-menu & {
                color: transparent;
            }
        }
    }

    .menu-icon {
        /* icon created in CSS */
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        width: 40%;
        height: 3px;
        background-color: #FFF;
        z-index: 510;

        .opened-menu & {
            background-color: transparent !important;
        }

        &:before, &:after {
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: #FFF;
            transition: transform 0.3s, height 0.3s;
        }

        &:before {
            transform: translateY(-10px) rotate(0deg);
            
            .opened-menu & {
                background-color: $main-color;
                transform: translateY(0) rotate(45deg);
            }
        }

        &:after {
            transform: translateY(10px) rotate(0deg);

            .opened-menu & {
                background-color: $main-color;
                transform: translateY(0) rotate(-45deg);
            }
        }
    }
}

// .page-wrapper, .page-header {
// 	transition: all .3s;
// 	transform: translateX(0);
//     .opened-menu &  {
//         transform: translateX(-260px);
//     }
// }

// .opened-menu {
//     overflow: hidden;
// }

.main-nav {
    position: absolute;
    z-index: 1600;
    width: 300px;
    padding-top: 100px;
    top: 0;
    right: 0;
    background: #000;
    height: 101vh;
    transition: all .3s;
    transform: translateX(100%);

    @include breakpoint(1176px) {
        width: calc((100vw - 1176px) / 2 + 300px);
    }

    .opened-menu & {
        transform: translateX(0);
    }

    ul {
        padding: 20px 0;
        margin: 0;
        max-width: 285px;
    }

    li {
        text-align: right;
        list-style: none;
        margin: 0;
    }

    a {
        color: #FFF;
        text-transform: uppercase;
        display: block;
        font-weight: 500;
        font-size: 1.1em;
        padding: 10px 20px;

        &:hover {
            color: lighten($main-color, 15%);
            text-decoration: none;
        }
    }

    .phone {
        margin-top: 50px;
        a {
            color: $main-color;
        }
        strong {
            font-weight: 500;
        }
    }
}
