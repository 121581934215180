$main-color: #60b22e;
$second-color: #60b22e;

// Breakpoint configurations
@include breakpoint-set('to ems', true);

// Based on Skeleton Framework
$mobile: 400px;
$phablet: 550px;
$tablet: 750px;
$desktop: 1000px;
$desktop-hd: 1200px;

$xs: max-width 767px;
$sm: 768px;
$sm-only: 768px 991px;
$md: 992px;
$md-only: 992px 1199px;
$lg: 1200px;

$jeet-gutter: 3;
